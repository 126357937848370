<template >
  <div class="card">
    <div class="card-header">
      <div class="d-flex m-10">
        <div class="p-2 flex-grow-1 align-self-center" style="margin-left: 2rem">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="th" value="th" v-model="selected_type.checked"
              @change="check_seleted('th')" />
            <label class="form-check-label" for="th">คนไทย</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="en" value="en" v-model="selected_type.checked"
              @change="check_seleted('en')" />
            <label class="form-check-label" for="en">FOREIGNER</label>
          </div>
        </div>
        <div class="p-2">
          <img src="@/assets/logo.png" style="height: 90px" alt="..." />
        </div>
      </div>
    </div>

    <RegisterTh v-if="selected_type.checked == 'th'"></RegisterTh>

    <RegisterEn v-else></RegisterEn>

  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import RegisterTh from "@/components/Register/RegisterTh";
import RegisterEn from "@/components/Register/RegisterEn";

export default defineComponent({
  name: "Register",
  components: {
    RegisterTh,
    RegisterEn,
  },

  setup() {
    const selected_type = ref([]);
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      //console.log(selected_type);
      check_seleted("th");
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const check_seleted = (lng) => {
      selected_type.value.checked = [lng];
      //console.log(selected_type.value.checked);
    };
    return {
      selected_type,
      check_seleted,
    };
  },
});
</script>

<style scoped>
.card {
  background-image: url("~@/assets/register_bg.jpg");
  background-position: center;
  background-size: cover, contain;
  background-repeat: no-repeat;
  height: 50%;
  margin: 0;
  width: auto;
}

.form-control form-control-solid {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

.form-check-label {
  color: white;
  font-size: 18px;
}

.card-header {
  padding: 0;
  margin-bottom: 0;
  border-bottom: none;
  background-color: transparent;
}

.bg-register {
  background-color: #0000;
}

.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input:checked+span {
  color: var(--color);
  border: 1px solid var(--color);
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
