<template>
  <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
    ref="formContainer" novalidate="novalidate">
    <div class="card-body">
      <h1 style="text-align: center; font-size: 40px; color: white">
        สมัครสมาชิก
      </h1>
      <!--begin::Card body-->
      <div class="box-register">
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อจริง-นามสกุลจริง</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-4">
            <Field as="select" name="fn" class="form-control form-control-solid" @change="checkFn($event)"
              v-model="data_register.fn">
              <option value="" selected disabled>คำนำหน้า</option>
              <option value="นาย">นาย</option>
              <option value="นาง">นาง</option>
              <option value="นางสาว">นางสาว</option>
              <option value="อื่นๆ">อื่นๆ</option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="fn" />
              </div>
            </div>
          </div>

          <div class="col-4">
            <!--begin::Label-->
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="firstname" class="form-control form-control-solid" placeholder="ชื่อ"
                v-model="data_register.firstname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="firstname" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>

          <div class="col-4">
            <!--begin::Label-->
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="lastname" class="form-control form-control-solid" placeholder="นามสกุล"
                v-model="data_register.lastname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="lastname" />
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>

          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
            <Field type="text" name="fn_other" class="form-control form-control-solid" placeholder="คำนำหน้า"
              v-model="data_register.fn_other" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="fn_other" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-auto">
            <label for="nickname" class="col-form-label fw-bold">ชื่อเล่น</label>
            <!--end::Label-->
          </div>
          <!--begin::Col-->
          <div class="col-4">
            <Field type="text" name="nickname" class="form-control form-control-solid" placeholder="ชื่อเล่น"
              v-model="data_register.nickname" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="nickname" />
              </div>
            </div>
          </div>

          <div class="col-auto">
            <label for="gender" class="col-form-label fw-bold fs-6 required">เพศ</label>
          </div>
          <div class="col-4">
            <Field as="select" name="gender" class="form-control form-control-solid" v-model="data_register.gender">
              <option value="" selected disabled>โปรดเลือก</option>
              <option value="ชาย">ชาย</option>
              <option value="หญิง">หญิง</option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="gender" />
              </div>
            </div>
            <!--begin::Col-->
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-3 justify-content-end text-end">
            <label class="col-form-label required fw-bold">วันเกิด</label>
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-3">
                <Field as="select" name="day" class="form-control form-control-solid">
                  <option value="" disabled selected>วัน</option>
                  <option v-for="index in 31" :key="index" :value="index">
                    {{ index }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="day" />
                  </div>
                </div>
              </div>
              <div class="col-5">
                <Field as="select" name="month" class="form-control form-control-solid">
                  <option value="" disabled selected>เดือน</option>
                  <option v-for="n in monthNamesThai" :key="n.id" :value="n.id">
                    {{ n.name }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="month" />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <Field as="select" name="year" class="form-control form-control-solid">
                  <option value="" disabled selected>ปี (พ.ศ.)</option>
                  <option v-for="(n, index) in setRange()" :key="index" :value="n">
                    {{ n }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="year" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-2">
            <label for="gender" class="col-form-label fw-bold fs-6 required">อาชีพ</label>
          </div>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-10">
            <Field as="select" name="job" class="form-control form-control-solid" @change="checkJob($event)"
              v-model="data_register.job">
              <option value="" selected disabled>โปรดเลือก</option>
              <option value="ข้าราชการ">ข้าราชการ</option>
              <option value="ธุรกิจส่วนตัว">ธุรกิจส่วนตัว</option>
              <option value="พนักงานเอกชน">พนักงานเอกชน</option>
              <option value="ค้าขาย">ค้าขาย</option>
              <option value="เกษตกรรม">เกษตรกรรม</option>
              <option value="อาชีพอิสระ">อาชีพอิสระ</option>

              <option value="อื่นๆ">อื่นๆ</option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="job" />
              </div>
            </div>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row mt-1" v-if="job_status">
            <Field type="text" name="job_other" class="form-control form-control-solid" placeholder="อาชีพ"
              v-model="data_register.job_other" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="job_other" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="tel" class="form-control form-control-solid" placeholder="เบอร์โทรศัพท์"
              v-model="data_register.tel" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="tel" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">อีเมลล์</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="email" class="form-control form-control-solid" placeholder="อีเมลล์"
              v-model="data_register.email" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">รหัสบัตรประชาชน</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="id_card" class="form-control form-control-solid" placeholder="รหัสบัตรประชาชน"
              v-model="data_register.id_card" maxlength="13" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="id_card" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-3 justify-content-end text-end">
            <label class="col-form-label required fw-bold">หมดอายุ</label>
          </div>
          <div class="col-9">
            <div class="row">
              <div class="col-3">
                <Field as="select" name="exp_day" class="form-control form-control-solid">
                  <option value="" disabled selected>วัน</option>
                  <option v-for="index in 31" :key="index" :value="index">
                    {{ index }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="exp_day" />
                  </div>
                </div>
              </div>
              <div class="col-5">
                <Field as="select" name="exp_month" class="form-control form-control-solid">
                  <option value="" disabled selected>เดือน</option>
                  <option v-for="n in monthNamesThai" :key="n.id" :value="n.id">
                    {{ n.name }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="exp_month" />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <Field as="select" name="exp_year" class="form-control form-control-solid">
                  <option value="" disabled selected>ปี (พ.ศ.)</option>
                  <option v-for="(n, index) in setRange_exp()" :key="index" :value="n">
                    {{ n }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="exp_year" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-6 justify-content-center">
          <!--begin::Label-->

          <label class="col-lg-4 col-form-label required fw-bold fs-6">อัพโหลดรูปบัตรประชาชน</label>
          <div class="col-lg-8 fv-row">
            <div v-if="imageUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
              <div class="row">
                <div style="position: relative" class="col-4" v-for="(item, index) in imageUrl" :key="index">
                  <div class="xx" @click="deleteImage(index)">
                    <span>x</span>
                  </div>
                  <img class="images" :src="item" />
                </div>
              </div>
            </div>
            <input type="file" id="getFilecamera" multiple="multiple" accept="image/jpg, image/jpeg ,image/png"
              @input="pickFile" style="display: none" capture="camera" />
            <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg, image/png"
              @input="pickFile" style="display: none" />
            <div class="d-flex justify-content-center">
              <button type="button" @click="selectImage(1)" class="button-upload">
                <img src="@/assets/camera.png" class="icon-ima" /> &nbsp;
                ถ่ายรูป
              </button>
              <div style="padding-left: 8px; padding-right: 8px"></div>
              <button type="button" @click="selectImage(2)" class="button-upload">
                <img src="@/assets/picture.png" class="icon-ima" />&nbsp;แนบรูป
              </button>

              <Field type="hidden" name="imageUrl" v-model="imageUrl" />
            </div>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="imageUrl" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-6">
          <!--begin::Label-->

          <label for="gender" class="col-form-label fw-bold fs-6 required">ที่อยู่ตามบัตรประชาชน</label>
          <div class="col-lg-8 fv-row">
            <Field type="text-area" name="address" class="form-control form-control-solid"
              placeholder="ที่อยู่ตามบัตรประชาชน" v-model="data_register.address" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="address" />
              </div>
            </div>
          </div>
        </div>

        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">จังหวัด</label>

            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field name="province" class="form-control form-control-solid" as="select"
                v-model="data_register.province">
                <option value="" selected disabled>โปรดเลือก</option>
                <option v-for="(item, key) in uniqueProvince" :key="key" :value="key">
                  {{ item.province }}
                </option>
                <!--:selected="value && value.includes(role.id)"-->
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="province" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">อำเภอ/เขต</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field name="amphoe" class="form-control form-control-solid" as="select" v-model="data_register.amphoe">
                <option value="" selected disabled>โปรดเลือก</option>
                <option v-for="(item, key) in uniqueAmphoe" :key="key" :value="key">
                  {{ item.amphoe }}
                </option>
                <!--:selected="value && value.includes(role.id)"-->
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="amphoe" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">ตำบล/แขวง</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field name="district" class="form-control form-control-solid" as="select"
                v-model="data_register.district" @change="setZipcode(data_register.district)">
                <option value="" selected disabled>โปรดเลือก</option>
                <option v-for="(item, key) in uniqueDistrict" :key="key" :value="key">
                  {{ item.district }}
                </option>
                <!--:selected="value && value.includes(role.id)"-->
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="district" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label class="col-lg-4 col-form-label required fw-bold fs-6">รหัสไปรษณีย์</label>
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="zipcode" class="form-control form-control-solid" placeholder="รหัสไปรษณีย์"
                v-model="data_register.zipcode" disabled />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="zipcode" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="d-flex justify-center align-center mb-2">
          <button type="submit" class="button">ลงทะเบียน</button>
        </div>

        <!--begin::Input group-->

        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!-- <div class="card-footer">
          <div class="d-flex justify-center align-center">
            <button type="submit" class="button">ลงทะเบียน</button>
          </div>
        </div> -->
  </Form>
</template>

<script>
import liff from "@line/liff";
import useRegister from "@/service/api/register";
import json from "@/service/DataStore.json";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import { useI18n } from "vue-i18n";

import * as Yup from "yup";

export default defineComponent({
  name: "Registerth",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤษจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    const { t, te } = useI18n();
    const i18n = useI18n();
    i18n.locale.value = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    const selected_type = ref([]);
    let isLoading = ref(false);
    const imageUrl = ref([]);
    const { Register } = useRegister();
    const { SconfirmNc, Sconfirm, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    let job_status = ref(false);
    const items = ref(json);
    const data_register = store.getters.getRegister;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const idcard_regex = /^[0-9]\d*$/;
    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      // fullname: Yup.string()
      //   .required("กรุณากรอก ชื่อ-นามสกุล")
      //   .label("ชื่อ-นามสกุล"),
      firstname: Yup.string()
        .required("กรุณากรอกชื่อ")
        .label("ชื่อ"),
      lastname: Yup.string()
        .required("กรุณากรอกนามสกุล")
        .label("นามสกุล"),
      gender: Yup.string().required("กรุณาเลือก เพศ").label("เพศ"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),

      day: Yup.string().required("กรุณาเลือก วัน"),
      month: Yup.string().required("กรุณาเลือก เดือน"),
      year: Yup.string().required("กรุณาเลือก ปี"),
      job: Yup.string().required("กรุณาเลือก อาชีพ").label("อาชีพ"),
      job_other: Yup.string()
        .when("job", {
          is: (job) => job === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก อาชีพ"),
        })
        .label("อาชีพ"),
      id_card: Yup.string()
        .required("กรุณากรอกรหัสบัตรประชาชน")
        .matches(idcard_regex, "รูปแบบบัตรประชาชนไม่ถูกต้อง")
        .min(13, "รูปแบบบัตรประชาชนไม่ถูกต้อง")
        .max(13, "รูปแบบบัตรประชาชนไม่ถูกต้อง"),
      email: Yup.string().required("กรุณากรอก อีเมลล์").label("อีเมลล์"),
      imageUrl: Yup.array().min(1, "กรุณาแนบรูปบัตรประชาชน"),
      address: Yup.string().required("กรุณาเลือก อาชีพ").label("อาชีพ"),
      province: Yup.string().required("กรุณาเลือก จังหวัด").label("จังหวัด"),
      amphoe: Yup.string().required("กรุณาเลือก อำเภอ/เขต").label("อำเภอ/เขต"),
      district: Yup.string()
        .required("กรุณาเลือก ตำบล/แขวง")
        .label("ตำบล/แขวง"),
      zipcode: Yup.string()
        .required("กรุณากรอก รหัสไปรษณีย์")
        .label("รหัสไปรษณีย์"),
      exp_day: Yup.string().required("กรุณาเลือก วัน"),
      exp_month: Yup.string().required("กรุณาเลือก เดือน"),
      exp_year: Yup.string().required("กรุณาเลือก ปี"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const setRange_exp = () => {
      let value = [];
      let i;
      for (i = 2600; i >= 2400; i--) {
        value.push(i);
      }
      return value;
    };

    const setRange = () => {
      let value = [];
      let i;
      for (i = 2565; i >= 2400; i--) {
        value.push(i);
      }
      return value;
    };

    const setday = (day, month, year) => {
      let y = year - 543;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    const onSubmitRegister = (values) => {
      values.register_type = "th";
      values.birthday = setday(values.day, values.month, values.year);
      values.id_card_exp_day = setday(
        values.exp_day,
        values.exp_month,
        values.exp_year
      );
      store.dispatch(SET_REGISTER, values);
      console.log(values);

      Sconfirm("ยืนยัน การลงทะเบียน?", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    function checkJob(event) {
      if (event.target.value === "อื่นๆ") {
        job_status.value = true;
      } else {
        job_status.value = false;
      }
    }

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    const uniqueProvince = computed(() => {
      return items.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.province]: current,
        });
      }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });

    function setZipcode(val) {
      let result = val;
      let data = items.value.filter(
        (item) =>
          item.district === result && item.amphoe === data_register.amphoe
      );
      data_register.zipcode = data[0].zipcode;
    }

    const background_img = () => {
      return "@/assets/register_bg.png";
    };

    const check_seleted = (lng) => {
      selected_type.value.checked = [lng];
      i18n.locale.value = lng;
      localStorage.setItem("lang", lng);
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      monthNamesThai,
      setRange,
      setday,
      job_status,
      checkJob,
      useRegister,
      setRange_exp,
      selectImage,
      deleteImage,
      pickFile,
      imageUrl,
      uniqueDistrict,
      setZipcode,
      uniqueAmphoe,
      uniqueProvince,
      background_img,
      selected_type,
      check_seleted,
      translate,
    };
  },
});
</script>

<style scoped>
.card-body {
  margin-top: 0;
  background-color: transparent;
  flex: unset !important;
  padding: 16px;
}

.box-register {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 1rem;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
</style>
