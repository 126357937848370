<template>
  <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
    ref="formContainer" novalidate="novalidate">
    <div class="card-body">
      <h1 style="text-align: center; font-size: 40px; color: white">
        Register
      </h1>
      <!--begin::Card body-->
      <div class="box-register">
        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Name&Surname</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-4">
            <Field as="select" name="fn" class="form-control form-control-solid" @change="checkFn($event)"
              v-model="data_register.fn">
              <option value="" selected disabled>Title</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="fn" />
              </div>
            </div>
          </div>

          <div class="col-4">
            <!--begin::Label-->
            <!--end::Label-->

            <!--begin::Col-->
            <div class="col-lg-8 fv-row">
              <Field type="text" name="lastname" class="form-control form-control-solid" placeholder="Surname"
                v-model="data_register.lastname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="lastname" />
                </div>
              </div>
            </div>



            <!--end::Col-->
          </div>
          <div class="col-4">
            <div class="col-lg-4 fv-row">
              <Field type="text" name="firstname" class="form-control form-control-solid" placeholder="Given Name"
                v-model="data_register.firstname" />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="firstname" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Col-->
          <!--begin::Col-->
          <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
            <Field type="text" name="fn_other" class="form-control form-control-solid" placeholder="คำนำหน้า"
              v-model="data_register.fn_other" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="fn_other" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-auto">
            <label for="nickname" class="col-form-label fw-bold">Nickname</label>
            <!--end::Label-->
          </div>
          <!--begin::Col-->
          <div class="col-3">
            <Field type="text" name="nickname" class="form-control form-control-solid" placeholder="Nickname"
              v-model="data_register.nickname" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="nickname" />
              </div>
            </div>
          </div>

          <div class="col-2">
            <label for="gender" class="col-form-label fw-bold fs-6 required">Gender</label>
          </div>
          <div class="col-4">
            <Field as="select" name="gender" class="form-control form-control-solid" v-model="data_register.gender">
              <option value="" selected disabled>selected</option>
              <option value="male">male</option>
              <option value="female">female</option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="gender" />
              </div>
            </div>
            <!--begin::Col-->
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <div class="col-4">
            <label class="col-form-label required fw-bold">Date of Birth</label>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-4">
                <Field as="select" name="day" class="form-control form-control-solid">
                  <option value="" disabled selected>dd</option>
                  <option v-for="index in 31" :key="index" :value="index">
                    {{ index }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="day" />
                  </div>
                </div>
              </div>
              <div class="col-4">
                <Field as="select" name="month" class="form-control form-control-solid">
                  <option value="" disabled selected>mm</option>
                  <option v-for="n in monthNamesEng" :key="n.id" :value="n.id">
                    {{ n.name }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="month" />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <Field as="select" name="year" class="form-control form-control-solid">
                  <option value="" disabled selected>yy</option>
                  <option v-for="(n, index) in setRange()" :key="index" :value="n">
                    {{ n }}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="year" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Nationality</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="nationality" class="form-control form-control-solid" placeholder="Nationality"
              v-model="data_register.nationality" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="nationality" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Country</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field name="country" class="form-control form-control-solid" as="select" v-model="data_register.country">
              <option value="" selected disabled>selected</option>
              <option v-for="(item, key) in uniqueCounty" :key="key" :value="key">
                {{ item.country }}
              </option>
            </Field>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="country" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Phone NO.</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="tel" class="form-control form-control-solid" placeholder="Phone No."
              v-model="data_register.tel" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="tel" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="email" class="form-control form-control-solid" placeholder="email"
              v-model="data_register.email" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="email" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6">
          <!--begin::Label-->
          <label class="col-lg-4 col-form-label required fw-bold fs-6">Passport NO.</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <Field type="text" name="passport_no" class="form-control form-control-solid" placeholder="Passport No."
              v-model="data_register.passport_no" maxlength="7" />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="passport_no" />
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>

        <div class="row mb-6 justify-content-center">
          <!--begin::Label-->

          <label class="col-lg-4 col-form-label required fw-bold fs-6">Upload Passport</label>
          <div class="col-lg-8 fv-row">
            <div v-if="imageUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
              <div class="row">
                <div style="position: relative" class="col-4" v-for="(item, index) in imageUrl" :key="index">
                  <div class="xx" @click="deleteImage(index)">
                    <span>x</span>
                  </div>
                  <img class="images" :src="item" />
                </div>
              </div>
            </div>
            <input type="file" id="getFilecamera" multiple="multiple" accept="image/jpg, image/jpeg ,image/png"
              @input="pickFile" style="display: none" capture="camera" />
            <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg, image/png"
              @input="pickFile" style="display: none" />
            <div class="d-flex justify-content-center">
              <button type="button" @click="selectImage(1)" class="button-upload">
                <img src="@/assets/camera.png" class="icon-ima" /> &nbsp; Take
                Picture
              </button>
              <div style="padding-left: 8px; padding-right: 8px"></div>
              <button type="button" @click="selectImage(2)" class="button-upload">
                <img src="@/assets/picture.png" class="icon-ima" />&nbsp;Browse
                File
              </button>

              <Field type="hidden" name="imageUrl" v-model="imageUrl" />
            </div>

            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="imageUrl" />
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-center align-center mb-2">
          <button type="submit" class="button">Confirm</button>
        </div>

        <!--begin::Input group-->

        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!-- <div class="card-footer">
          <div class="d-flex justify-center align-center">
            <button type="submit" class="button">ลงทะเบียน</button>
          </div>
        </div> -->
  </Form>
</template>

<script>
import liff from "@line/liff";
import useRegister from "@/service/api/register";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import { useI18n } from "vue-i18n";

import json_country from "@/service/CountyJson.json";

import * as Yup from "yup";

export default defineComponent({
  name: "Registerth",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const monthNamesEng = ref([
      { id: "01", name: "Jan" },
      { id: "02", name: "Feb" },
      { id: "03", name: "Mar" },
      { id: "04", name: "Apr" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "Aug" },
      { id: "09", name: "Sep" },
      { id: "10", name: "Oct" },
      { id: "11", name: "Nov" },
      { id: "12", name: "Dec" },
    ]);

    const { t, te } = useI18n();
    const i18n = useI18n();
    i18n.locale.value = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    const selected_type = ref([]);
    let isLoading = ref(false);
    const imageUrl = ref([]);
    const { Register } = useRegister();
    const { SconfirmNc, Sconfirm, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");

    const items_country = ref(json_country);
    const data_register = store.getters.getRegister;
    const register = Yup.object().shape({
      fn: Yup.string().required("please select").label("Prefict"),
      firstname: Yup.string()
        .required("please enter given name")
        .label("Given Name"),
      lastname: Yup.string()
        .required("please enter surname")
        .label("Surname"),
      gender: Yup.string().required("please select").label("gender"),
      tel: Yup.string().required("please enter tel"),

      day: Yup.string().required("select day"),
      month: Yup.string().required("select month"),
      year: Yup.string().required("select year"),
      passport_no: Yup.string().required("please enter passport"),
      email: Yup.string().required("please enter email").label("email"),
      imageUrl: Yup.array().min(1, "please upload picture"),
      country: Yup.string().required("please select").label("country"),
      nationality: Yup.string()
        .required("please enter nationality")
        .label("nationality"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });


    var currentTime = new Date();
    var year = currentTime.getFullYear()

    const setRange = () => {
      let value = [];
      let i;
      for (i = year; i >= 1900; i--) {
        value.push(i);
      }
      return value;
    };

    const setday = (day, month, year) => {
      let y = year;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };

    const onSubmitRegister = (values) => {

      values.register_type = "en";
      values.birthday = setday(values.day, values.month, values.year);

      store.dispatch(SET_REGISTER, values);
      console.log(values);

      Sconfirm("ยืนยัน การลงทะเบียน?", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    const uniqueCounty = computed(() => {
      return items_country.value.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.country]: current,
        });
      }, {});
    });

    const background_img = () => {
      return "@/assets/register_bg.png";
    };

    const check_seleted = (lng) => {
      selected_type.value.checked = [lng];
      i18n.locale.value = lng;
      localStorage.setItem("lang", lng);
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      onSubmitRegister,
      register,
      iconMale,
      iconFemale,
      data_register,
      monthNamesEng,
      setRange,
      setday,
      useRegister,
      selectImage,
      deleteImage,
      pickFile,
      imageUrl,
      background_img,
      selected_type,
      check_seleted,
      translate,
      uniqueCounty,
    };
  },
});
</script>

<style scoped>
.card-body {
  margin-top: 0;
  background-color: transparent;
  flex: unset !important;
  padding: 16px;
}

.box-register {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 1rem;
  border-radius: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
</style>
